import { render, staticRenderFns } from "./TechnicalA.vue?vue&type=template&id=832e00ec&scoped=true&"
import script from "./TechnicalA.vue?vue&type=script&lang=js&"
export * from "./TechnicalA.vue?vue&type=script&lang=js&"
import style0 from "./TechnicalA.vue?vue&type=style&index=0&id=832e00ec&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "832e00ec",
  null
  
)

export default component.exports