<template>
  <div class="technical">
    <div class="topImg">
      <img src="../assets/R-C.jpg" alt="">
    </div>
    <div class="introduction">
      <div class="introductionBox">
        <div class="left_bg"></div>
        <div class="right_bg"></div>
        <h1 class="inner_t">
          首页  &gt; 技术
        </h1>
        <ul class="server_tab">
          <li class="server_cons">
            <img src="../assets/R-C.jpg" alt="">
            <div>11111111</div>
          </li>
          <li class="server_cons">
            <div>22222222222</div>
            <img src="../assets/R-C.jpg" alt="">
          </li>
          <li class="server_cons">
            <img src="../assets/R-C.jpg" alt="">
            <div>33333333</div>
          </li>
          <li class="server_cons">
            <div>44444444</div>
            <img src="../assets/R-C.jpg" alt="">
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "TechnicalA"
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: 微软雅黑;
}

.technical{
  height: 1400px;
  /*overflow: hidden;*/
  background-color: #dfdfdf;

}
.topImg>img {
  width: 100%;
  height: 300px;
}

.introduction {
  width: 100%;
  position: absolute;
  top: 250px;
}

.introductionBox {
  width: 1200px;
  /*border: 1px solid red;*/
  //margin: auto;
  height: 1249px;
  background-color: white;
  position: relative;
}

.left_bg {
  width: 29px;
  height: 174px;
  background: url(../assets/bg_con.png) no-repeat;
  position: absolute;
  left: -28px;
  top: 0px;
}

.right_bg {
  width: 29px;
  height: 174px;
  background: url(../assets/bg_con.png) no-repeat -29px 0px;
  position: absolute;
  right: -28px;
  top: 0px;
}

.inner_t {
  height: 35px;
  background-color: #f5f5f5;
  line-height: 35px;
  color: #30313a;
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid #cecece;
  padding: 0 100px;
}

.server_tab {
  list-style: none;
  width: 910px;
  margin: auto;
  color: #4c4948;
  /*border: 1px solid;*/
  padding: 50px 0;
}

.server_cons {
  /*width: 50%;*/
  height: 291px;
  /*border: 1px solid royalblue;*/
  display: flex;
}

.server_cons>img {
  width: 50%;
}

.server_cons>div {
  width: 50%;
  background-color: #f4f4f4;
}
</style>