import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TechnicalA from "@/views/TechnicalA";
import FertilizerDisplay from "@/components/FertilizerDisplay";
import CompanyProfile from "@/components/CompanyProfile";
import ChemicalFertilizer from "@/components/ChemicalFertilizer";
import ProductDetails from "@/components/ProductDetails";

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    redirect:'/HomeView',
  },
  {
    path: '/HomeView',
    component: HomeView
  },
  {
    path: '/TechnicalA',
    component: TechnicalA
  },
 {
    path: '/FertilizerDisplay',
    component: FertilizerDisplay
  },
 {
    path: '/CompanyProfile',
    component: CompanyProfile
  },
    {
    path: '/ChemicalFertilizer',
    component: ChemicalFertilizer,
    name:ChemicalFertilizer
  },
    {
    path: '/ProductDetails',
        name:'ProductDetails',
    component: ProductDetails,
  },

]

const router = new VueRouter({
  routes
})

export default router

