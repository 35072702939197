<template>
  <div class="container">
    <el-row>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
  <div class="footer" >
    <div class="footerCon">
      <ul>
        <li style="margin-left: 20px;">联系我们：400-902-9698</li>
      </ul>
      <p style="color: #888888;">COPYRIGHT 2014 上禾化工 SHANGHEHUAGONG 版权所有号</p>
      <ul class="code">
        <li>
          <img src="../assets/微信图片_20230327151901.jpg" width="100">
          <p>官方微博</p>
        </li>
        <li>
          <img src="../assets/微信图片_20230327151901.jpg" width="100">
          <p>官方公众号</p>
        </li>
      </ul>
    </div>
  </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
name: "PageFooter"
}
</script>

<style scoped>
*{
  font-family: 微软雅黑;
}
.footer {
  height: 150px;
  background: #3e3d3d;
  color: #f4f4f4;
  display: flex;
}
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.footerCon {
  width: 1200px;
  margin: auto;
  /*display: flex;*/
  position: relative;
}

.footerCon>p {
  margin-left: 40px;
}

.footer ul {
  list-style: none;
  display: flex;
  font-size: 18px;
}

.footer li {
  font-style: italic;
  padding-right: 55px;
}

.footer li p {
  text-align: center;
}

.code {
  height: 100px;
  position: absolute;
  right: 0px;
  top: -15px;
  /*border: 1px solid red;*/
}
</style>