<template>
  <div class="technical">
    <div class="topImg">
      <img src="../assets/R-C.jpg" alt="">
    </div>
    <div class="introduction">
      <div class="introductionBox">
        <div class="left_bg"></div>
        <div class="right_bg"></div>
        <h1 class="inner_t">
          公司简介
        </h1>
        <div class="server_tab">
    <p style="text-indent: 2em; font-size: 22px;">
      深圳上禾化工科技有限公司位于深圳市宝安区。该公司是一家专注于研究土壤修复集生产、加工、销售、肥料经营及科学农业技术咨询服务为一体的新型肥料生产企业。公司在深圳、河北均设生产基地。公司拥有优质农用稀土资源，把农用稀土加到肥料里，生产出高端优质的水溶肥、微量元素水溶肥料、微生物肥料等。公司现有“田力欣”、“劲领灌”、“多元稀”等商标注册。
      公司的理念为：专为农业服务，致力于修复土壤，提高农产品品质，增加农民收入。为乡村振兴助力，为我国农业现代化添彩。
    </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "CompanyProfile"
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: 微软雅黑;
}
.technical{
  height: 700px;
  background-color: #dfdfdf;
}

.topImg>img {
  width: 100%;
  height: 300px;
}

.introduction {
  width: 100%;
  position: absolute;
  top: 250px;
}

.introductionBox {
  width: 1200px;
  /*border: 1px solid red;*/
  //margin: auto;
  height: 500px;
  background-color: white;
  position: relative;
}

.left_bg {
  width: 29px;
  height: 174px;
  background: url(../assets/bg_con.png) no-repeat;
  position: absolute;
  left: -28px;
  top: 0px;
}

.right_bg {
  width: 29px;
  height: 174px;
  background: url(../assets/bg_con.png) no-repeat -29px 0px;
  position: absolute;
  right: -28px;
  top: 0px;
}

.inner_t {
  height: 35px;
  background-color: #f5f5f5;
  line-height: 35px;
  color: #30313a;
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid #cecece;
  padding: 0 100px;
}

.server_tab {
  list-style: none;
  width: 910px;
  margin: auto;
  color: #4c4948;
  /*border: 1px solid;*/
  padding: 50px 0;
}

</style>