<template>
  <div id="app">
    <div class="container">
      <el-row>
        <el-col :xs="24" :sm="12" :md="8" :lg="6">
<!--     <scale-box :width="1920" :height="2080" bgc="transparent" :delay="100">-->
       <div>
         <PageHeader></PageHeader>
       </div>
     <router-view/>
       <div>
         <PageFooter></PageFooter>
       </div>
<!--     </scale-box>-->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
// import ScaleBox from "vue2-scale-box";
// import HomePage from "@/components/HomePage";


export default {
  // name: 'app',
  components: {
    // ScaleBox,
    PageHeader,
    PageFooter,
    // HomePage,

  }
}
</script>
<style>
#app{
  font-family: 微软雅黑;
  width: 1200px;
  margin: auto;
}
  body {
    margin: 0;
    padding: 0;
  }

.top{
  height: auto;
  min-height: calc(100vh - 220px);
}
</style>
