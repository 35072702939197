<template>
  <div class="technical">
    <div class="topImg">
      <img src="../assets/R-C.jpg" alt="" style="width:100%">
    </div>
    <div class="introduction">
      <div class="introductionBox">
        <div class="left_bg"></div>
        <div class="right_bg"></div>
        <h1 class="inner_t">
          肥效展示
        </h1>
        <div class="server_tab">
          <div class="mar_t10">
            <div class="l_cir"></div>
            <div class="r_con">
              <h5>2019-10-20</h5>
              <h1>肥效展示标题</h1>
              <h4>阅读全文 &gt;</h4>
            </div>
          </div>
          <div class="mar_t10">
            <div class="l_cir"></div>
            <div class="r_con">
              <h5>2019-10-20</h5>
              <h1>肥效展示标题</h1>
              <h4>阅读全文 &gt;</h4>
            </div>
          </div>
          <div class="mar_t10">
            <div class="l_cir"></div>
            <div class="r_con">
              <h5>2019-10-20</h5>
              <h1>肥效展示标题</h1>
              <h4>阅读全文 &gt;</h4>
            </div>
          </div>
          <div class="mar_t10">
            <div class="l_cir"></div>
            <div class="r_con">
              <h5>2019-10-20</h5>
              <h1>肥效展示标题</h1>
              <h4>阅读全文 &gt;</h4>
            </div>
          </div>
          <div class="mar_t10">
            <div class="l_cir"></div>
            <div class="r_con">
              <h5>2019-10-20</h5>
              <h1>肥效展示标题</h1>
              <h4>阅读全文 &gt;</h4>
            </div>
          </div>
          <div class="mar_t10">
            <div class="l_cir"></div>
            <div class="r_con">
              <h5>2019-10-20</h5>
              <h1>肥效展示标题</h1>
              <h4>阅读全文 &gt;</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "CompanyProfile"
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: 微软雅黑;
}
.technical{
  height: 800px;
  background-color: #dfdfdf;

}
.topImg>img {
  width: 100%;
  height: 300px;
}

.introduction {
  width: 100%;
  position: absolute;
  top: 250px;
}

.introductionBox {
  width: 1200px;
  /*border: 1px solid red;*/
  //margin: auto;
  height: 621px;
  background-color: white;
  position: relative;
}

.left_bg {
  width: 29px;
  height: 174px;
  background: url(../assets/bg_con.png) no-repeat;
  position: absolute;
  left: -28px;
  top: 0px;
}

.right_bg {
  width: 29px;
  height: 174px;
  background: url(../assets/bg_con.png) no-repeat -29px 0px;
  position: absolute;
  right: -28px;
  top: 0px;
}

.inner_t {
  height: 35px;
  background-color: #f5f5f5;
  line-height: 35px;
  color: #30313a;
  font-size: 12px;
  font-weight: normal;
  border-bottom: 1px solid #cecece;
  padding: 0 100px;
}

.server_tab {
  list-style: none;
  width: 910px;
  margin: auto;
  color: #4c4948;
  /*border: 1px solid;*/
  padding: 50px 0;
}

.mar_t10 {
  margin-top: 10px;
  display: flex;
}

.l_cir {
  height: 68px;
  width: 40px;
  background: url(../assets/icon_cir.png) no-repeat;
  background-position: left center;
  cursor: pointer;
}

.r_con h5 {
  font-size: 12px;
  font-family: 'Microsoft YaHei';
  color: #888888;
  padding-top: 5px;
}

.r_con h1 {
  font-size: 18px;
  color: #4c4948;
  font-weight: 900;
  height: 21px;
  line-height: 21px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 25px;
}

.r_con h4 {
  font-size: 14px;
  font-family: 'Microsoft YaHei';
  color: #f5a11a;
  cursor: pointer;
}

</style>