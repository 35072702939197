<template>
  <div class="home">
   <div class="top">

     <HomePage></HomePage>
   </div>

<!--    <PageFooter></PageFooter>-->

  </div>
</template>

<script>
// @ is an alias to /src


// import PageHeader from "@/components/PageHeader";
// import PageFooter from "@/components/PageFooter";
import HomePage from "@/components/HomePage";


export default {
  name: 'HomeView',
  components: {

    // PageHeader,
    // PageFooter,
    HomePage,

  }
}
</script>
<style>

*{
  padding: 0;
  margin: 0;
  font-family: 微软雅黑;
}
a{
  text-decoration: none;
}
.top{
  min-height: calc(100vh - 220px);
}
</style>