<template>
<div class="PageHeader">
<div class="PageHeader-1">
  <img src="../assets/logo1.jpg" alt=""  >
  <div class="jump">
    <router-link tag="span" to="/HomeView">首页</router-link>
    <router-link tag="span" to="/ChemicalFertilizer">产品</router-link>
    <router-link tag="span" to="/TechnicalA">技术</router-link>
    <router-link tag="span" to="/FertilizerDisplay">肥效展示</router-link>
    <router-link tag="span" to="/CompanyProfile">关于我们</router-link>
  </div>
</div>
</div>
</template>

<script>

export default {
name: "PageHeader",
}
</script>

<style scoped>
*{
  padding: 0;
  margin: 0;
  font-family: 微软雅黑;
}
a{
  text-decoration: none;
}
.PageHeader{
  width:100%;
  height: 100px;
  box-sizing: border-box;

  /*background-color: red;*/
}
.PageHeader-1{
  width: 1200px;
  height: 100px;
  margin: 0 auto;
  /*background-color: red;*/
  display: flex;
  align-items: center;
}
/*首页logo*/
.PageHeader-1 img:nth-child(1){
  width: 90px;
  margin: 10px 20px;
}
.PageHeader-1 span{
  margin: 0 40px;
}
.jump{
  margin-left: 150px;
}
.jump span{
  cursor:pointer;
  font-size:20px;
  font-weight: 900;
  /*font-family: "Hiragino Maru Gothic ProN",楷体;*/
}
</style>