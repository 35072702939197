<template>
  <div class="container">
    <el-row>
      <el-col :xs="24" :sm="12" :md="8" :lg="6">
  <div class="zhu">
    <a-carousel :dot-position="dotPosition" effect="fade" autoplay arrow easing="linear">
      <div><img src="../assets/g4.jpg" alt=""></div>
      <div><img src="../assets/g3.jpg" alt=""></div>
      <div><img src="../assets/g2.jpg" alt=""></div>
      <div><img src="../assets/g8281496.jpg" alt=""></div>
    </a-carousel>
  </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>

export default {
  name: 'HomePage',
  data() {
    return {
      dotPosition: 'right',
    };
  },
  components:{

  },


}
</script>


<style scoped>
*{
  padding: 0;
  margin: 0;
  width: auto;
  font-family: 微软雅黑;
}
a{
  text-decoration: none;
}
/* For demo */
.ant-carousel >>> .slick-slide {
  text-align: center;
  /*height: 160px;*/
  /*line-height: 160px;*/
  background: #dfdfdf;
  overflow: hidden;

}

.ant-carousel >>> .slick-slide img {
  height: 700px;
  width: 100%;
  margin: 0 auto;
}


</style>
